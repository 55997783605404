import React from "react"
import { Link, graphql } from "gatsby"
import Headerjawn from "../components/headerjawn"
import Layout from "../components/bloglayout"
import Footerjawn from "../components/footerjawn"

import posed from 'react-pose'
import { TransitionState } from "gatsby-plugin-transition-link"
import { tween } from "popmotion";


const Box = posed.div({
  hidden: { 
    opacity: [0,1], 
    left: '-100%',
    transition: props => tween({ ...props, duration: 0 })
  },
  visible: { 
    opacity: [1,0], 
    left: '100%',
    transition: props => tween({ ...props, duration: 1000 })
  },
})

export default ({ data }) => {
  // console.log(data)
  return (
    <>
    <Headerjawn/>  

    <Layout>
      <section className="blog-cc">
        <h2>BLOG</h2>

        <p>You'll have to add TransitionLink to the blog link(s) for transitions to work here</p>

        <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <article className="preview" key={node.id}>
            <Link              
              to={node.fields.slug}                         
            >
              <h3>
                {node.frontmatter.title}{" "}
                <span>
                  — {node.frontmatter.date}
                </span>
              </h3>
              <p>{node.excerpt}</p>
            </Link>
          </article>
        ))}
      </section>

      <section className="blog-cc-side">
        <h2>I be a sidebar didebbebeb dibby doo</h2>
        <p>skfjssl ksldfkl lksjdfl lksjdf sd</p>
        <p>skfjssl ksldfkl lksjdfl lksjdf sd</p>
      </section>
    </Layout>

    <Footerjawn/>

    <TransitionState>
        {({ transitionStatus }) => {
            return (
                <Box
                  className="box"
                  pose={
                    ['entering', 'entered'].includes(transitionStatus)
                      ? 'hidden'
                      : 'visible'
                  }
                />
            )
        }}
    </TransitionState>
    <style
          dangerouslySetInnerHTML={{
            __html: `
            .box {
                background: #64fec3;
            }
        `,
          }}
    />
    
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {            
            slug          
          }
          excerpt
        }
      }
    }
  }
`